import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { QueryParams, Trader } from 'types';

const url = `${env.apiUrl}/traders`;

const getAllAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Trader[]> =>
    (await axios.get(`${url}/${role}`, { params })).data;

const findOneAsRole =
  (role: UserRole) =>
  async (id: string): Promise<Trader> =>
    (await axios.get(`${url}/${role}/${id}`)).data;

const getMyInfo = async (): Promise<Trader> =>
  (await axios.get(`${url}/me`)).data;

const update = async ({
  data,
}: {
  data: Partial<
    Pick<
      Trader,
      | 'workStatus'
      | 'payinMinOrderAmount'
      | 'payinMaxOrderAmount'
      | 'payoutMaxActiveOrders'
    >
  >;
}): Promise<Trader> => (await axios.patch(`${url}/me`, data)).data;

const updateByRole =
  (role: UserRole) =>
  async ({
    id,
    trader,
  }: {
    id: string;
    trader: Partial<Trader>;
  }): Promise<Trader> =>
    (await axios.patch(`${url}/${role}/${id}`, trader)).data;

// TODO: extend from crud api
export const tradersApi = {
  getAllAsRole,
  getMyInfo,
  findOneAsRole,
  updateByRole,
  update,
};
